<template>
  <div class="floatAction d-none">
    <router-link to="/register">
      <div class="green-circle">
        <img class="img" src="@/assets/img/document.svg" alt="documento" />
      </div>
    </router-link>
    <div>
      <p class="green-text mb-0">{{ data.text }} </p>
      <p class="green-text mb-0 ">{{ data.text2 }} </p>
      <p class="green-text mb-0 fw-bold">{{ data.markedText }} </p>
    </div>
  </div>
</template>

<script>
import store from "../../store";

export default {
  name: "FloatAction",
  data() {
    return {
      data: {
        text: "",
        markedText: "",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.floatSection) {
        this.data = { ...store.state.sections.floatSection.components };
      }
    },
  },
};
</script>



<style scoped>
.floatAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 777;
  position: fixed;
  bottom: 5%;
  right: 2%;
}

.green-circle {
  background-color: #27ba54;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.img {
  width: 40px;
}

.green-text {
  color: #27ba54;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem !important;
}

@media (max-width: 982px) {
  .floatAction {
    bottom: 5%;
    top: initial;
    right: 5%;
  }

  .img {
    width: 30px;
  }

  .green-circle {
    background-color: #27ba54;
    width: 55px;
    height: 55px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
